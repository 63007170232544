import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { parallelActions } from './../../app/store';

import { timestampToString, activatePopover } from './../common/helpers';

import {
    selectAdvertisementsState, setInputAction, setUsers,
    openAdvertisementsListAction, openAddAdvertisementAction, openEditAdvertisementAction,
    addAdvertisementAction,
    updateAdvertisementAction,
    changeAdvertisementIsActiveAction, changeAdvertisementIsProbabilityStartsWithAdsAction, markAdvertisementAction,
    changeApplicationIdFilterAction,
} from './advertisementsSlice';

import styles from './advertisements.css';

function advertisementsTable(dispatch, advertisements) {
    const onAdvertisementSelect = id => dispatch(openEditAdvertisementAction(id));
    const onAdvertisementDelete = (event, id, name) => {
        if (event.target.className.indexOf('no-click') === -1) {
            if ('buttons' in event && event.buttons === 2) {
                if (window.confirm('Are you sure want to remove ' + name + '?')) {
                    dispatch(markAdvertisementAction(id));
                }
            }
        }
    };

    const advertisementsElements = advertisements.map(
        (advertisement, index) => <tr onMouseDown={event => onAdvertisementDelete(event, advertisement.id, advertisement.name)} key={advertisement.id}>
            <th onClick={event => onAdvertisementSelect(advertisement.id)} className="bg-info" scope="row">{advertisement.id}</th>
            <td>{advertisement.application.name}</td>
            <td>{advertisement.name}</td>
            <td>{advertisement.adType.name}</td>
            <td>{advertisement.supremoAdId}</td>
            <td>
                <ul>
                    {advertisement.admobAdIds.map((admobAdId, admobAdIdsIndex) => <li key={admobAdId + '-' + advertisement.admobAdIdsIndex}>{admobAdId}</li>)}
                </ul>
            </td>
            <td>{advertisement.probability}</td>
            <td>{advertisement.comments}</td>
            <td>{timestampToString(advertisement.createdAt)}</td>
            <td className="no-click">
                <div className="no-click form-check form-switch">
                  <input className="no-click form-check-input form-check-input-black" type="checkbox" checked={advertisement.active} onChange={e => dispatch(changeAdvertisementIsActiveAction(advertisement.id, index, e.target.checked))}/>
                </div>
            </td>
            <td className="no-click">
                <div className="no-click form-check form-switch">
                  <input className="no-click form-check-input form-check-input-black" type="checkbox" checked={advertisement.probabilityStartsWithAds} onChange={e => dispatch(changeAdvertisementIsProbabilityStartsWithAdsAction(advertisement.id, index, e.target.checked))}/>
                </div>
            </td>
        </tr>
    );

    return (
    <table className="table">
        <thead>
            <tr className="bg-border">
                <th scope="col">ID</th>
                <th scope="col">Application</th>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Supremo ad id</th>
                <th scope="col">Admob ad ids</th>
                <th scope="col">Capability</th>
                <th scope="col">Comment</th>
                <th scope="col">Created at</th>
                <th scope="col">Active</th>
                <th scope="col">Starts with Ads</th>
            </tr>
        </thead>
        <tbody onContextMenu={e => {e.preventDefault(); return false;}}>
            {advertisementsElements}
        </tbody>
    </table>
    )
}

function advertisementForm(advertisementsState, dispatch, onSubmit) {
    const applicationsElements = advertisementsState.applications.map(
        (application, index) => <option key={index} value={application.id}>{application.name}</option>
    );
    const adTypesElements = advertisementsState.adTypes.map(
        (adType, index) => <option key={index} value={adType.id}>{adType.name}</option>
    );

    const inputs = {
        name: advertisementsState.inputs.name,
        onName: e => dispatch(setInputAction({value: e.target.value, input: 'name'})),
        applicationId: advertisementsState.inputs.applicationId,
        onApplicationId: e => dispatch(setInputAction({value: e.target.value, input: 'applicationId'})),
        adTypeId: advertisementsState.inputs.adTypeId,
        onAdTypeId: e => dispatch(setInputAction({value: e.target.value, input: 'adTypeId'})),
        admobAdIds: advertisementsState.inputs.admobAdIds,
        onAdmobAdIds: e => dispatch(setInputAction({value: e.target.value, input: 'admobAdIds'})),
        comments: advertisementsState.inputs.comments,
        onComments: e => dispatch(setInputAction({value: e.target.value, input: 'comments'})),
        probability: advertisementsState.inputs.probability,
        onProbability: e => dispatch(setInputAction({value: e.target.value, input: 'probability'})),
        active: advertisementsState.inputs.active,
        onActive: e => dispatch(setInputAction({value: e.target.checked, input: 'active'})),
        probabilityStartsWithAds: advertisementsState.inputs.probabilityStartsWithAds,
        onProbabilityStartsWithAds: e => dispatch(setInputAction({value: e.target.checked, input: 'probabilityStartsWithAds'})),
        onSubmit: event => { event.preventDefault(); onSubmit(event); },
        onBack: () => dispatch(openAdvertisementsListAction())
    }

    return (
    <div className="container">
        <form onSubmit={inputs.onSubmit}>
            <div className="mb-3">
                <label htmlFor="applicationInput" className="form-label">Application</label>
                <select id="applicationInput" className="form-select" name="application"
                        value={inputs.applicationId} onChange={inputs.onApplicationId}>
                  <option disabled value="-1">Choose application</option>
                  {applicationsElements}
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="nameInput" className="form-label">Ad title</label>
                <input id="nameInput" className="form-control" type="text" name="name"
                       required min="3"
                       value={inputs.name} onChange={inputs.onName}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="adTypeInput" className="form-label">Ad type</label>
                <select id="adTypeInput" className="form-select" name="adType"
                        value={inputs.adTypeId} onChange={inputs.onAdTypeId}>
                  <option disabled value="-1">Choose ad type</option>
                  {adTypesElements}
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="admobAdIdsInput" className="form-label">Admob ad id</label>
                <textarea id="admobAdIdsInput" className="form-control" name="admobAdIds"
                          value={inputs.admobAdIds} onChange={inputs.onAdmobAdIds}
                ></textarea>
            </div>

            <div className="mb-3">
                <label htmlFor="commentsInput" className="form-label">Comments</label>
                <textarea id="commentsInput" className="form-control" name="comments"
                          value={inputs.comments} onChange={inputs.onComments}
                ></textarea>
            </div>

            <div className="mb-3">
                <label htmlFor="probabilityInput" className="form-label" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content-id="popover-content">Capability <span className="bg-info custom-icon">&#8263;</span></label>
                <input id="probabilityInput" className="form-control" type="number" name="probability"
                       required min="1" max="100"
                       value={inputs.probability} onChange={inputs.onProbability}
                />
            </div>

            <div id="popover-content" className="d-none">
              1 - это 10 процентов по старой формуле<br/>
              2 - это 20 процентов по старой формуле<br/>
              3 - это 30 процентов по старой формуле<br/>
              4 - это 40 процентов по старой формуле<br/>
              5 - это 50 процентов по старой формуле<br/>
              6 - это 60 процентов по старой формуле<br/>
              7 - это 70 процентов по старой формуле<br/>
              8 - это 80 процентов по старой формуле<br/>
              9 - это 90 процентов по старой формуле<br/>
              10 - это 100 процентов по старой формуле - <b>Показывать рекламу всегда</b><br/>
              Формула с периодичностью:<br/>
              11 - это показ рекламы через раз<br/>
              12 - это показ рекламы через два<br/>
              13 - это показ рекламы через три<br/>
              14 - это показ рекламы через четыре<br/>
              15 - это показ рекламы через пять<br/>
              16 - это показ рекламы через шесть<br/>
              17 - это показ рекламы через семь<br/>
              18 - это показ рекламы через восемь<br/>
              19 - это показ рекламы через девять<br/>
              Формула с рандомом:<br/>
              20 - рандом 1 процент вероятности<br/>
              21 - рандом 10 процентов вероятности<br/>
              22 - рандом 20 процентов вероятности<br/>
              23 - рандом 30 процентов вероятности<br/>
              24 - рандом 40 процентов вероятности<br/>
              25 - рандом 50 процентов вероятности<br/>
              26 - рандом 60 процентов вероятности<br/>
              27 - рандом 70 процентов вероятности<br/>
              28 - рандом 80 процентов вероятности<br/>
              29 - рандом 90 процентов вероятности
            </div>

            <div className="mb-3">
                <div className="form-check form-switch">
                  <input id="activeInput" className="form-check-input" type="checkbox"
                         checked={inputs.active} onChange={inputs.onActive} />
                  <label className="form-check-label" htmlFor="activeInput">Active</label>
                </div>
            </div>

            <div className="mb-3">
                <div className="form-check form-switch">
                  <input id="probabilityStartsWithAdsInput" className="form-check-input" type="checkbox"
                         checked={inputs.probabilityStartsWithAds} onChange={inputs.onProbabilityStartsWithAds} />
                  <label className="form-check-label" htmlFor="probabilityStartsWithAdsInput">Probability starts with Ads</label>
                </div>
            </div>

            <div className="row">
                <div className="col-auto">
                    <button type="submit" className="form-control btn btn-black">Save</button>
                </div>
                <div className="col-auto">
                    <button onClick={inputs.onBack} className="form-control btn btn-black" type="button">Back</button>
                </div>
            </div>
        </form>
    </div>
    )
}

function AdvertisementsAdd() {
    const advertisementsState = useSelector(selectAdvertisementsState);
    const dispatch = useDispatch();

    // A hook to apply popover after page was rendered
    useEffect(() => activatePopover());

    return advertisementForm(
        advertisementsState, dispatch,
        e => { if (window.confirm('Are you sure?')) { dispatch(addAdvertisementAction()) } } // onSubmit
    );
}

function AdvertisementsEdit() {
    const advertisementsState = useSelector(selectAdvertisementsState);
    const dispatch = useDispatch();

    // A hook to apply popover after page was rendered
    useEffect(() => activatePopover());

    return advertisementForm(
        advertisementsState, dispatch,
        e => { if (window.confirm('Are you sure?')) { dispatch(updateAdvertisementAction()) } } // onSubmit
    );
}

function AdvertisementsList() {
    const advertisementsState = useSelector(selectAdvertisementsState);
    const dispatch = useDispatch();

    const applicationsElements = advertisementsState.applications.map(
        (application, index) => <option key={index} value={application.id}>{application.name}</option>
    );

    const inputs = {
        applicationIdFilter: advertisementsState.applicationIdFilter,
        onApplicationIdFilter: e => dispatch(changeApplicationIdFilterAction(e.target.value)),
        onAddNewAdvertisement: () => dispatch(openAddAdvertisementAction())
    }

    return (
    <div className="overflow-x-scroll">
        <div className="row mb-3 p-3">
            <div className="col-md-3">
                <select className="form-select" name="application"
                        value={inputs.applicationIdFilter} onChange={inputs.onApplicationIdFilter}>
                  <option value="-1">Choose application</option>
                  {applicationsElements}
                </select>
            </div>
            <div className="col-md-9">
                <div className="row justify-content-end">
                    <div className="col-auto">
                        <button onClick={inputs.onAddNewAdvertisement} className="btn btn-black">Add new ad</button>
                    </div>
                </div>
            </div>
        </div>

        {advertisementsTable(dispatch, advertisementsState.advertisements)}
    </div>
    )
}

export function Advertisements() {
    const advertisementsState = useSelector(selectAdvertisementsState);
    const dispatch = useDispatch();

    switch (advertisementsState.action) {
      case 'add':
        return <AdvertisementsAdd />
      case 'edit':
        return <AdvertisementsEdit />
      case 'list':
      default:
        return <AdvertisementsList />
    }
}