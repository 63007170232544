import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { timestampToString } from './../common/helpers';

import {
    selectAdvertisementsState,
    unMarkAdvertisementAction,
} from './advertisementsBinSlice';

import styles from './advertisementsBin.css';

function advertisementsTable(dispatch, advertisements) {
    const onAdvertisementDelete = (event, id, name) => {
        if (event.target.className.indexOf('no-click') === -1) {
            if ('buttons' in event && event.buttons === 2) {
                if (window.confirm('Are you sure want to restore ' + name + '?')) {
                    dispatch(unMarkAdvertisementAction(id));
                }
            }
        }
    };

    const advertisementsElements = advertisements.map(
        (advertisement, index) => <tr onMouseDown={event => onAdvertisementDelete(event, advertisement.id, advertisement.name)} key={advertisement.id}>
            <th className="bg-info" scope="row">{advertisement.id}</th>
            <td>{advertisement.application.name}</td>
            <td>{advertisement.name}</td>
            <td>{advertisement.adType.name}</td>
            <td>{advertisement.supremoAdId}</td>
            <td>
                <ul>
                    {advertisement.admobAdIds.map((admobAdId, admobAdIdsIndex) => <li key={admobAdId + '-' + advertisement.admobAdIdsIndex}>{admobAdId}</li>)}
                </ul>
            </td>
            <td>{advertisement.probability}</td>
            <td>{advertisement.comments}</td>
            <td>{timestampToString(advertisement.createdAt)}</td>
            <td className="no-click">
                <div className="no-click form-check form-switch">
                  <input className="no-click form-check-input form-check-input-black" type="checkbox" checked={advertisement.active} disabled/>
                </div>
            </td>
            <td className="no-click">
                <div className="no-click form-check form-switch">
                  <input className="no-click form-check-input form-check-input-black" type="checkbox" checked={advertisement.probabilityStartsWithAds} disabled/>
                </div>
            </td>
            <td className="no-click">
                <div className="no-click form-check form-switch">
                  <span>{advertisement.daysUntilDeletion}</span>
                </div>
            </td>
        </tr>
    );

    return (
    <table className="table">
        <thead>
            <tr className="bg-border">
                <th scope="col">ID</th>
                <th scope="col">Application</th>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Supremo ad id</th>
                <th scope="col">Admob ad ids</th>
                <th scope="col">Capability</th>
                <th scope="col">Comment</th>
                <th scope="col">Created at</th>
                <th scope="col">Active</th>
                <th scope="col">Starts with Ads</th>
                <th scope="col" title="Marks advertisement to be scheduled for deletion">Mark</th>
            </tr>
        </thead>
        <tbody onContextMenu={e => {e.preventDefault(); return false;}}>
            {advertisementsElements}
        </tbody>
    </table>
    )
}

function AdvertisementsBinList() {
    const advertisementsState = useSelector(selectAdvertisementsState);
    const dispatch = useDispatch();

    return (
    <div className="overflow-x-scroll">
        {advertisementsTable(dispatch, advertisementsState.advertisementsBin)}
    </div>
    )
}

export function AdvertisementsBin() {
    const advertisementsState = useSelector(selectAdvertisementsState);

    switch (advertisementsState.action) {
      case 'list':
      default:
        return <AdvertisementsBinList />
    }
}