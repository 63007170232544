import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setInputDefaultAction, setCommonExtraReducerCases } from './../common/reducer';

import { loadAdvertisementsCall, loadAdTypesAndApplicationsCall, saveNewAdvertisementCall, changeAdvertisementIsActiveCall, changeAdvertisementIsProbabilityStartsWithAdsCall, markAdvertisementCall, loadAdvertisementCall, updateAdvertisementCall, changeApplicationIdFilterCall } from './advertisementsApi';

function emptyInputs(advertisement = undefined) {
    return {
        id: advertisement === undefined ? '' : advertisement.id,
        name: advertisement === undefined ? '' : advertisement.name,
        applicationId: advertisement === undefined ? -1 : advertisement.application.id,
        adTypeId: advertisement === undefined ? -1 : advertisement.adType.id,
        admobAdIds: advertisement === undefined ? '' : advertisement.admobAdIds.join(','),
        supremoAdId: advertisement === undefined ? '' : advertisement.supremoAdId,
        comments: advertisement === undefined ? '' : advertisement.comments,
        probability: advertisement === undefined ? 0 : advertisement.probability,
        active: advertisement === undefined ? true : advertisement.active,
        probabilityStartsWithAds: advertisement === undefined ? true : advertisement.probabilityStartsWithAds
    }
}

const initialState = {
  advertisements: [],
  adTypes: [],
  applications: [], // Applications available to user

  applicationIdFilter: -1,
  inputs: emptyInputs(),

  action: 'list'
};

const initializeAsyncInternalAction = createAsyncThunk(
  'advertisements/init',
  async (parameters) => {
    return await loadAdvertisementsCall(parameters.applicationIdFilter);
  }
);

const openAddNewAdvertisementAsyncInternalAction = createAsyncThunk(
  'advertisements/load-ad-types-and-applications',
  async () => {
    return await loadAdTypesAndApplicationsCall();
  }
);

const addNewAdvertisementAsyncInternalAction = createAsyncThunk(
  'advertisements/add-new-advertisement',
  async (parameters) => {
    return await saveNewAdvertisementCall(parameters.name, parameters.applicationId, parameters.adTypeId, parameters.admobAdIds, parameters.comments, parameters.probability, parameters.active, parameters.probabilityStartsWithAds, parameters.applicationIdFilter);
  }
);

const changeAdvertisementIsActiveAsyncInternalAction = createAsyncThunk(
  'advertisements/change-new-is-active',
  async (parameters) => {
    return await changeAdvertisementIsActiveCall(parameters.id, parameters.index, parameters.isActive);
  }
);

const changeAdvertisementIsProbabilityStartsWithAdsInternalAction = createAsyncThunk(
  'advertisements/change-new-is-probability',
  async (parameters) => {
    return await changeAdvertisementIsProbabilityStartsWithAdsCall(parameters.id, parameters.index, parameters.isProbabilityStartsWithAds);
  }
);

const markAdvertisementInternalAction = createAsyncThunk(
  'advertisements/change-new-is-marked',
  async (parameters) => {
    return await markAdvertisementCall(parameters.id, parameters.applicationIdFilter);
  }
);

const openAdvertisementAsyncInternalAction = createAsyncThunk(
  'advertisements/load-advertisement',
  async (parameters) => {
    return await loadAdvertisementCall(parameters.id);
  }
);

const updateAdvertisementAsyncInternalAction = createAsyncThunk(
  'advertisements/update-advertisement',
  async (parameters) => {
    return await updateAdvertisementCall(parameters.id, parameters.name, parameters.applicationId, parameters.adTypeId, parameters.admobAdIds, parameters.comments, parameters.probability, parameters.active, parameters.probabilityStartsWithAds, parameters.applicationIdFilter);
  }
);

const changeApplicationIdFilterAsyncInternalAction = createAsyncThunk(
  'advertisements/change-application-id-filter',
  async (parameters) => {
    return await changeApplicationIdFilterCall(parameters.applicationIdFilter);
  }
);


export const advertisementsSlice = createSlice({
  name: 'advertisements',
  initialState,
  reducers: {
    setInputAction: setInputDefaultAction,
    openAdvertisementsListAction: (state, action) => {
      state.action = 'list';
    }
  },
  extraReducers: (builder) => {
    setCommonExtraReducerCases(builder, initializeAsyncInternalAction, (state, action) => {
      state.action = 'list';
      state.advertisements = action.payload[0].elements;
      state.applications = action.payload[1].elements;
    });
    setCommonExtraReducerCases(builder, openAddNewAdvertisementAsyncInternalAction, (state, action) => {
      state.action = 'add';
      state.adTypes = action.payload[0].elements;
      state.applications = action.payload[1].elements;
      state.inputs = emptyInputs();
    });
    setCommonExtraReducerCases(builder, addNewAdvertisementAsyncInternalAction, (state, action) => {
      state.action = 'list';
      state.advertisements = action.payload.elements;
    });
    setCommonExtraReducerCases(builder, changeAdvertisementIsActiveAsyncInternalAction, (state, action) => {
      state.advertisements[action.payload.index].active = action.payload.isActive;
    });
    setCommonExtraReducerCases(builder, changeAdvertisementIsProbabilityStartsWithAdsInternalAction, (state, action) => {
      state.advertisements[action.payload.index].probabilityStartsWithAds = action.payload.isProbabilityStartsWithAds;
    });
    setCommonExtraReducerCases(builder, markAdvertisementInternalAction, (state, action) => {
      state.action = 'list';
      state.advertisements = action.payload.elements;
    });
    setCommonExtraReducerCases(builder, openAdvertisementAsyncInternalAction, (state, action) => {
      state.action = 'edit';
      state.inputs = emptyInputs(action.payload[0]);
      state.adTypes = action.payload[1].elements;
      state.applications = action.payload[2].elements;
    });
    setCommonExtraReducerCases(builder, updateAdvertisementAsyncInternalAction, (state, action) => {
      state.action = 'list';
      state.advertisements = action.payload.elements;
    });
    setCommonExtraReducerCases(builder, changeApplicationIdFilterAsyncInternalAction, (state, action) => {
      state.action = 'list';
      state.advertisements = action.payload.advertisements.elements;
      state.applicationIdFilter = action.payload.applicationIdFilter;
    });
  }
});

export const selectAdvertisementsState = (state) => state.advertisements;

export const { setInputAction, setUsers, openAdvertisementsListAction } = advertisementsSlice.actions;

export const initializeAction = () => (dispatch, getState) => {
  const advertisementsState = selectAdvertisementsState(getState());

  dispatch(initializeAsyncInternalAction({
    applicationIdFilter: advertisementsState.applicationIdFilter
  }));
};

export const openAddAdvertisementAction = () => (dispatch, getState) => {
  dispatch(openAddNewAdvertisementAsyncInternalAction());
};

export const addAdvertisementAction = () => (dispatch, getState) => {
  const advertisementsState = selectAdvertisementsState(getState());

  if (advertisementsState.inputs.applicationId === -1 || advertisementsState.inputs.adTypeId === -1 ) {
    return;
  }

  dispatch(addNewAdvertisementAsyncInternalAction({
    ...advertisementsState.inputs,
    applicationIdFilter: advertisementsState.applicationIdFilter
  }));
};

export const changeAdvertisementIsActiveAction = (id, index, isActive) => (dispatch, getState) => {
  dispatch(changeAdvertisementIsActiveAsyncInternalAction({
    id: id,
    index: index,
    isActive: isActive
  }));
};

export const changeAdvertisementIsProbabilityStartsWithAdsAction = (id, index, isProbabilityStartsWithAds) => (dispatch, getState) => {
  dispatch(changeAdvertisementIsProbabilityStartsWithAdsInternalAction({
    id: id,
    index: index,
    isProbabilityStartsWithAds: isProbabilityStartsWithAds
  }));
};

export const markAdvertisementAction = (id) => (dispatch, getState) => {
  const advertisementsState = selectAdvertisementsState(getState());

  dispatch(markAdvertisementInternalAction({
    id: id,
    applicationIdFilter: advertisementsState.applicationIdFilter
  }));
};

export const openEditAdvertisementAction = (id) => (dispatch, getState) => {
  dispatch(openAdvertisementAsyncInternalAction({
    id: id
  }));
};

export const updateAdvertisementAction = () => (dispatch, getState) => {
  const advertisementsState = selectAdvertisementsState(getState());

  if (advertisementsState.inputs.applicationId === -1 || advertisementsState.inputs.adTypeId === -1 ) {
    return;
  }

  dispatch(updateAdvertisementAsyncInternalAction({
    ...advertisementsState.inputs,
    applicationIdFilter: advertisementsState.applicationIdFilter
  }));
};

export const changeApplicationIdFilterAction = (applicationIdFilter) => (dispatch, getState) => {
  dispatch(changeApplicationIdFilterAsyncInternalAction({
    applicationIdFilter: applicationIdFilter
  }));
};

export default advertisementsSlice.reducer;