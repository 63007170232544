import React from 'react';
import { useDispatch } from 'react-redux';

import { parallelActions } from './../../app/store';

import { selectPageAction } from './../main/mainSlice';

import { initializeAction as usersInitializeAction } from './../users/usersSlice';
import { initializeAction as reportsInitializeAction } from './../reports/reportsSlice';
import { initializeAction as emergencyInitializeAction } from './../emergency/emergencySlice';
import { initializeAction as applicationsInitializeAction } from './../applications/applicationsSlice';
import { initializeAction as advertisementsInitializeAction } from './../advertisements/advertisementsSlice';
import { initializeAction as advertisementsBinInitializeAction } from './../advertisementsBin/advertisementsBinSlice';

function navigationElement(isActive, title, onClick, htmlElementId) {
    const containerClassName = isActive ? 'nav-item nav-item-custom nav-item-custom-selected' : 'nav-item nav-item-custom';
    return (
    <div className={containerClassName}>
        <button id={htmlElementId} className="btn btn-custom" onClick={onClick}>{title}</button>
    </div>
    )
}

function usersNavigation(isActive, onClick, htmlElementId) {
    return navigationElement(isActive, 'Users', onClick, htmlElementId);
}

function applicationsNavigation(isActive, onClick, htmlElementId) {
    return navigationElement(isActive, 'Projects', onClick, htmlElementId);
}

function advertisementsNavigation(isActive, onClick, htmlElementId) {
    return navigationElement(isActive, 'Ads', onClick, htmlElementId);
}

function advertisementsBinNavigation(isActive, onClick, htmlElementId) {
    return navigationElement(isActive, 'Ads (bin)', onClick, htmlElementId);
}

function emergencyNavigation(isActive, onClick, htmlElementId) {
    return navigationElement(isActive, 'Emergency', onClick, htmlElementId);
}

function reportsNavigation(isActive, onClick, htmlElementId) {
    return navigationElement(isActive, 'Reports', onClick, htmlElementId);
}

function logoutNavigation(onLogout) {
    return navigationElement(false, 'Log Out', onLogout);
}

function dispatchParallelActionSelectPage(dispatch, page, initializeAction) {
    return () => {
        dispatch(parallelActions([
            selectPageAction(page),
            initializeAction
        ]));
    };
}

export function Navigation(parameters) {
    const dispatch = useDispatch();

    const isAdmin = parameters.role === 'ADMIN';
    const selectedPage = parameters.selectedPage;

    const usersNavigationOnClick = dispatchParallelActionSelectPage(dispatch, 'users', usersInitializeAction())
    const applicationsNavigationOnClick = dispatchParallelActionSelectPage(dispatch, 'applications', applicationsInitializeAction())
    const advertisementsNavigationOnClick = dispatchParallelActionSelectPage(dispatch, 'advertisements', advertisementsInitializeAction())
    const advertisementsBinNavigationOnClick = dispatchParallelActionSelectPage(dispatch, 'advertisementsBin', advertisementsBinInitializeAction())
    const emergencyNavigationOnClick = dispatchParallelActionSelectPage(dispatch, 'emergency', emergencyInitializeAction())
    const reportsNavigationOnClick = dispatchParallelActionSelectPage(dispatch, 'reports', reportsInitializeAction())

    return (
    <div className="row no-side-margin">
        {isAdmin && usersNavigation(selectedPage === 'users', usersNavigationOnClick, 'usersMenu')}
        {isAdmin && applicationsNavigation(selectedPage === 'applications', applicationsNavigationOnClick, 'applicationsMenu')}
        {advertisementsNavigation(selectedPage === 'advertisements', advertisementsNavigationOnClick, 'advertisementsMenu')}
        {advertisementsBinNavigation(selectedPage === 'advertisementsBin', advertisementsBinNavigationOnClick, 'advertisementsBinMenu')}
        {isAdmin && emergencyNavigation(selectedPage === 'emergency', emergencyNavigationOnClick, 'emergencyMenu')}
        {isAdmin && reportsNavigation(selectedPage === 'reports', reportsNavigationOnClick, 'reportsMenu')}

        {logoutNavigation(parameters.onLogout)}
    </div>
    )
}