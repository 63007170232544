import { baseFetch, baseFetchText } from './../common/api';

function loadAdvertisementsPromise(resolve, reject) {
    let query = '?marked=true';
    return baseFetch(resolve, reject, `/api/admin/advertisements${query}`);
}

function loadAdTypesPromise(resolve, reject) {
    return baseFetch(resolve, reject, '/api/admin/ad-types');
}

function loadApplicationsPromise(resolve, reject) {
    return baseFetch(resolve, reject, '/api/admin/applications?sortByNameAsc=true');
}

function unMarkAdvertisementPromise(resolve, reject, id) {
    return baseFetchText(resolve, reject, `/api/admin/advertisements/${id}/mark/false`, { method: 'PUT' });
}

export function loadAdvertisementsCall() {
    return Promise.all([
        new Promise((resolve, reject) => {
            loadAdvertisementsPromise(resolve, reject)
                .then(json => resolve(json))
        }),
        new Promise((resolve, reject) => {
            loadApplicationsPromise(resolve, reject)
                .then(json => resolve(json))
        })
    ]);
}

export function loadAdTypesAndApplicationsCall() {
    return Promise.all([
        new Promise((resolve, reject) => {
            loadAdTypesPromise(resolve, reject)
                .then(json => resolve(json))
        }),
        new Promise((resolve, reject) => {
            loadApplicationsPromise(resolve, reject)
                .then(json => resolve(json))
        })
    ]);
}

export function unMarkAdvertisementCall(id) {
    return new Promise((resolve, reject) => {
        unMarkAdvertisementPromise(resolve, reject, id)
            .then(__text => loadAdvertisementsPromise(resolve, reject))
            .then(json => resolve(json))
    });
}

