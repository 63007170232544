import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setInputDefaultAction, setCommonExtraReducerCases } from './../common/reducer';

import { loadAdvertisementsCall, unMarkAdvertisementCall } from './advertisementsBinApi';

const initialState = {
  advertisementsBin: [],

  action: 'list'
};

const initializeAsyncInternalAction = createAsyncThunk(
  'advertisementsBin/init',
  async (parameters) => {
    return await loadAdvertisementsCall();
  }
);

const unMarkAdvertisementInternalAction = createAsyncThunk(
  'advertisementsBin/un-mark-advertisement',
  async (parameters) => {
    return await unMarkAdvertisementCall(parameters.id);
  }
);

export const advertisementsBinSlice = createSlice({
  name: 'advertisementsBin',
  initialState,
  reducers: {
    setInputAction: setInputDefaultAction,
    openAdvertisementsListAction: (state, action) => {
      state.action = 'list';
    }
  },
  extraReducers: (builder) => {
    setCommonExtraReducerCases(builder, initializeAsyncInternalAction, (state, action) => {
      state.action = 'list';
      state.advertisementsBin = action.payload[0].elements;
      state.applications = action.payload[1].elements;
    });
    setCommonExtraReducerCases(builder, unMarkAdvertisementInternalAction, (state, action) => {
      state.action = 'list';
      state.advertisementsBin = action.payload.elements;
    });
  }
});

export const selectAdvertisementsState = (state) => state.advertisementsBin;

export const openAdvertisementsListAction = advertisementsBinSlice.actions;

export const initializeAction = () => (dispatch, getState) => {
  dispatch(initializeAsyncInternalAction());
};

export const unMarkAdvertisementAction = (id) => (dispatch, getState) => {
  dispatch(unMarkAdvertisementInternalAction({
    id: id
  }));
};

export default advertisementsBinSlice.reducer;